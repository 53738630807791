import {ApiConfig} from "@/types.d";

const config: ApiConfig = {
  baseURL: (process.env.VUE_APP_API_URL || ""),
  endpoints: {
    intro: "/ptp/" + "intro",
    join_nearest_group: "/ptp/" + "user/study/accept",
    leave_nearest_group: "/ptp/" + "user/study/decline",
    config: "/ptp/" + "dashboard/config",
    status: "/ptp/" + "user/status",
    user: "/ptp/" + "dashboard/user/general",
    volunteer: "/ptp/" + "dashboard/volunteer/general",
    volunteer_set_intent: "/ptp/" + "volunteer/intent",
    volunteer_set_examinees: "/ptp/" + "volunteer/amount",
    levels: "/ptp/" + "dashboard/user/extended",
    detailed_level: "/ptp/" + "dashboard/user/level/extended",
    request_reviewer_change: "/ptp/" + "review/reviewer/change",
    confirm_participation: "/ptp/" + "review/confirmation",
    choose_best_reviewer: "/ptp/" + "review/reviewer/best",
    start_review: "/ptp/" + "review/start",
    review_place: "/ptp/" + "review/place",
    set_availability: "/ptp/" + "review/availability",
    toggle_vacation: "/ptp/" + "user/study/vacation",
    download_homework: "/ptp/" + "user/homework/download",
    upload_homework: "/ptp/" + "user/homework/upload",
    homework_survey: "/ptp/" + "user/homework/survey",
    theses: "/ptp/" + "mark/theses/list",
    video: "/ptp/" + "data/video",
    material: "/ptp/" + "data/materials",
    materials: "/ptp/" + "materials/general/list",
    all_materials: "/ptp/" + "materials/levels/list",
    set_mark: "/ptp/" + "user/mark/add",
    send_feedback: "/ptp/" + "user/feedback/save",
    start_from_midexam: "/ptp/" + "user/set/active",
    ask_for_help: "support/discord/hello"
  }
};


export const links: {
  [name: string]: string
} = {
  SIGN_IN: (process.env.VUE_APP_SIGN_IN_URL || ""),
  MANUAL: process.env.VUE_APP_LINKS_MANUAL || "",
  MANUAL_ONLINE_REVIEW: process.env.VUE_APP_LINKS_MANUAL_ONLINE_REVIEW || "",
  MANUAL_WARNINGS: process.env.VUE_APP_LINKS_MANUAL_WARNINGS || "",
  MANUAL_DOSTUPNIST: process.env.VUE_APP_LINKS_MANUAL_DOSTUPNIST || "",
  HOW_TO_UPLOAD: process.env.VUE_APP_LINKS_HOW_TO_UPLOAD || "",
};

export const systemAdminEmail: string = process.env.VUE_APP_SYSTEM_ADMIN_EMAIL || "";
export const commentMinLength: number = +(process.env.VUE_APP_MIN_COMMENT_LENGTH || 0);

export default config;
