import api from "@/api";

const state = {
  theses: []
};

const mutations = {
  updateTheses(state: any, data: any) {
    state.theses = data;
  }
};

const updateData = async (rootState: any, dispatch: any, level: number) => {
  if (rootState.user.me.role.includes("volunteer")) {
    await dispatch("user/get", true, {root: true});
  } else {
    await dispatch("levels/getExtendedLevel", level, {root: true});
  }
};

const actions = {
  async requestReviewerChange({dispatch}: any, params: any) {
    await api.post("request_reviewer_change", params);
    await dispatch("levels/getExtendedLevel", params.level, {root: true});
  },
  async chooseBestReviewer({dispatch}: any, params: any) {
    await api.post("choose_best_reviewer", params);
    await dispatch("levels/getExtendedLevel", params.level, {root: true});
  },
  async confirm({dispatch, rootState}: any, params: any) {
    await api.post("confirm_participation", params);
    await updateData(rootState, dispatch, params.level);
  },
  async requestOnlineReview({dispatch, rootState}: any, params: any) {
    await api.post("review_place", {
      ...params,
      type: "request",
      reviewNumber: 1
    });
    await updateData(rootState, dispatch, params.level);
  },
  async start({dispatch, rootState}: any, params: any) {
    await api.post("start_review", params);
    await updateData(rootState, dispatch, params.reviewerLevel);
  },
  async setReviewPlace({dispatch, rootState}: any, params: any) {
    await api.post("review_place", {
      ...params,
      type: "done"
    });
    await updateData(rootState, dispatch, params.level);
  },
  async setAvailability({dispatch, rootState}: any, params: any) {
    await api.post("set_availability", params);
    await updateData(rootState, dispatch, params.level);
  },
  async getHomeworkSurvey() {
    return await api.get("homework_survey");
  },
  async getTheses({commit}: any) {
    const {data} = await api.get("theses");
    commit("updateTheses", data);
  },
  async setMark({dispatch, rootState}: any, params: any) {
    await api.post("set_mark", params);
    await updateData(rootState, dispatch, params.authorLevel);
  },
  async saveVolunteerIntent({dispatch}: any, params: any) {
    await api.post("volunteer_set_intent", params);
    await dispatch("user/get", true, {root: true});
  },
  async saveVolunteerExaminees({dispatch}: any, params: any) {
    await api.post("volunteer_set_examinees", params);
    await dispatch("user/get", true, {root: true});
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
